.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    width: 100%;
  }
  
  .footer h5 {
    margin-bottom: 20px;
  }
  
  .footer form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer form .form-control {
    width: 300px; /* Ajustar el ancho aquí */
    display: inline-block;
    margin-right: 10px;
  }
  
  .footer form .btn-warning {
    display: inline-block;
  }
  
  .footer p {
    margin-top: 10px;
    color: #6c757d;
  }
  
  @media (max-width: 576px) {
    .footer form {
      flex-direction: column;
    }
  
    .footer form .form-control {
      width: 100%;
      margin: 0 0 10px 0;
    }
  
    .footer form .btn-warning {
      width: 100%;
    }
  }
  
  .invalid-feedback {
    color: red;
    margin-top: 5px;
  }
  