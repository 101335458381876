.home-screen {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.filter-toggle {
  display: none;
}

.filter-form {
  width: 20%;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.filter-form h2 {
  margin-bottom: 20px;
}

.filter-form form {
  display: flex;
  flex-direction: column;
}

.filter-form input,
.filter-form select,
.filter-form button {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.filter-form input[type="text"] {
  border: 1px solid #ccc;
}

.filter-form button[type="button"] {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.filter-form button[type="button"]:hover {
  background-color: #0056b3;
}

.price-slider {
  margin-bottom: 1rem;
}

.filter-form button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.filter-form button[type="button"] {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filter-form button[type="button"]:hover {
  background-color: #0056b3;
}

.product-list {
  width: 80%;
  padding: 20px;
}

.product-list h1 {
  margin-bottom: 20px;
}

.product-list .row {
  display: flex;
  flex-wrap: wrap;
}

.product-list .col-sm-12,
.product-list .col-md-6,
.product-list .col-lg-4 {
  margin-bottom: 20px;
}

select:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.no-products {
  text-align: center;
  margin-top: 20px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.back-to-top:hover {
  background-color: #0056b3;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .home-screen {
    flex-direction: column;
  }

  .filter-toggle {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }

  .filter-toggle button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }

  .filter-toggle button:hover {
    background-color: #0056b3;
  }

  .filter-form {
    display: none;
  }

  .product-list {
    width: 100%;
  }

  .product-list h1 {
    text-align: center;
  }

  .product-list .row {
    justify-content: center;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.pagination-container ul {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.pagination-container li {
  display: inline;
  margin: 0 5px;
}

.pagination-container li a {
  color: #007bff;
  text-decoration: none;
}

.pagination-container li a:hover {
  color: #0056b3;
}

/* Mobile-Only Modal */
.modal-body .form-group {
  margin-bottom: 15px; /* Add margin to separate form elements */
}

.products-per-page-selector {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.products-per-page-selector label {
  margin-right: 10px;
}

.products-per-page-selector select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}