.recommended-slider {
    margin: 50px 0;
    padding: 0 20px;
    position: relative;
}

.slider-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
}

.slider-item {
    padding: 10px;
    position: relative;
}

.image-container {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Capa transparente */
    z-index: 1;
    cursor: default;
}

.view-product-button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.view-product-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.view-product-button:hover {
    background-color: #0056b3;
}

/* Estilos para los dots */
.slick-dots {
    bottom: -30px;
}

.slick-dots li button:before {
    font-size: 12px;
    color: #00aaff;
}

.slick-dots li.slick-active button:before {
    color: #0077cc;
}
